import React from 'react';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import Button from '../Button';

interface ErrorBoundaryProps {
  hasError: boolean;
  eventId?: string;
}

interface Props {
  children: React.ReactNode;
  fallback?: () => React.ReactNode;
  t: (key: string) => string;
}

class ErrorBoundary extends React.Component<Props, ErrorBoundaryProps> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, eventId: undefined };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_SENTRY_DSN) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo as any);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render(): JSX.Element | React.ReactNode {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback();
      }
      const { t } = this.props;
      return (
        <Content>
          <h2>{`${t('something-went-wrong-one')}.`}</h2>
          {t('something-went-wrong-two')} <br />
          {t('something-went-wrong-three')} <br />
          {this.state.eventId && process.env.NODE_ENV !== 'development' && (
            <FeedbackButton
              onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
            >
              {t('report-feedback')}
            </FeedbackButton>
          )}
        </Content>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);

const Content = styled.div`
  padding: ${props => parseInt(props.theme.navHeight, 10) + 50}px 50px 100px;
  font-size: 18px;
  text-align: center;
`;

const FeedbackButton = styled(Button)`
  margin-top: 25px;
`;
