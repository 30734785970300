import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import { Flag } from '../Flag';
import { getPlatformName } from '../../../../utils/getPlatformName';

const envLink = process.env.REACT_APP_API_ENDPOINT;
export const generateEnvName = (env: string): string | undefined => {
  if (env?.includes('staging')) {
    return 'Staging';
  }
  if (env?.includes('beta')) {
    return 'Beta';
  }
  return;
};

export const SectionNavigation = (): JSX.Element => {
  const { t } = useTranslation();
  const { platform } = getPlatformName();

  return (
    <NavigationRoot>
      <NavigationWrapper>
        <NavigationItem exact activeClassName="selected" to="/">
          <Icon name={'robot'} width={15} height={15} />
          {t('contact-center-ai')}
        </NavigationItem>
        <NavigationItem activeClassName="selected" to="/automation">
          <Icon name={'automation'} width={18} height={15} />
          {t('automation')}
        </NavigationItem>
        <NavigationItem activeClassName="selected" to="/configuration">
          <Icon name={'configuration'} width={15} height={15} />
          {t('configuration')}
        </NavigationItem>
        <NavigationItem activeClassName="selected" to="/routing">
          <Icon name={'routing'} width={15} height={15} />
          {t('routing')}
        </NavigationItem>
        <NavigationItem activeClassName="selected" to="/data">
          <Icon name={'data'} width={15} height={15} />
          {t('data')}
        </NavigationItem>
        {platform === 'flex' && (
          <NavigationItem activeClassName="selected" to="/reports">
            <Icon name={'report'} width={15} height={15} />
            {t('reports')}
          </NavigationItem>
        )}
        {envLink && generateEnvName(envLink) && (
          <Flag styles={{ right: '40px' }} text={generateEnvName(envLink)} />
        )}
      </NavigationWrapper>
    </NavigationRoot>
  );
};

export const NavigationRoot = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  box-shadow: 0 1px 0 0 rgba(16, 0, 107, 0.1);
  background-color: ${props => props.theme.white};
`;
NavigationRoot.displayName = 'NavigationRoot';

export const NavigationWrapper = styled.div`
  position: relative;
  display: flex;
  max-width: ${props => props.theme.contentWidth};
  padding: 0 ${props => props.theme.contentGap};
  margin: 0 auto;
`;

export const NavigationItem = styled(NavLink)`
  display: flex;
  position: relative;
  flex: 0 0 auto;
  height: ${props => props.theme.navHeight};
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  font-weight: 600;
  color: ${props => props.theme.primaryColor};
  outline: none;
  cursor: pointer;

  svg {
    pointer-events: none;
    margin-right: 5px;
  }
  &.selected {
    background-color: ${props => props.theme.secondaryBgColor};
  }
`;
NavigationItem.displayName = 'NavigationItem';
