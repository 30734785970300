import React, { Suspense } from 'react';
import * as R from 'ramda';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NavigationModules } from '../modules/modules';
import { BasicLayout } from '../common/layouts/BasicLayout';
import { useAccountContext } from './AccountContext';
const PageIndex = lazy(() => import('../modules/AdBlockerPage/PageIndex'), 'PageIndex');
const HomePage = lazy(() => import('../modules/Dashboard/Home'), 'Home');
const AccountsPage = lazy(() => import('../modules/Accounts/root'));
import { CenteredSpinner } from '../common/components/utils/Spinner';
import lazy from '../utils/lazy';

export const Routes = (): JSX.Element => {
  const BASE_URL = '/';
  const routes: any[] = renderRoutes(NavigationModules, BASE_URL) || [];
  const renderedComponents = R.flatten(routes);
  const {
    config: { isNewAccount },
  } = useAccountContext();

  return !isNewAccount ? (
    <Suspense fallback={<CenteredSpinner />}>
      <Switch>
        <Route
          exact
          path={BASE_URL}
          render={() => (
            <BasicLayout>
              <HomePage />
            </BasicLayout>
          )}
        />

        {/* Renders all the array of Routes*/}
        {renderedComponents}

        <Route path="/accounts" component={AccountsPage} />
        <Route path="/ad-blocker-warning" component={PageIndex} />
      </Switch>
    </Suspense>
  ) : (
    <Suspense fallback={<CenteredSpinner />}>
      <Switch>
        <Route exact path={BASE_URL} render={() => <Redirect to="/accounts/new" />} />

        {/* Renders all the array of Routes*/}
        {renderedComponents}

        <Route path="/accounts" component={AccountsPage} />
        <Route path="/ad-blocker-warning" component={PageIndex} />
      </Switch>
    </Suspense>
  );
};

const PrivateRoute = props => {
  const { children, ...rest } = props;
  const {
    config: { isNewAccount },
  } = useAccountContext();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isNewAccount ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/accounts/new',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

interface NavItem {
  id: string;
  title: string;
  route: string;
  component: any;
  structure?: NavItem[];
}

const makeUrl = (url: string) => url.replace(/\/\//g, '/');
const renderRoutes = (navItems: NavItem[], baseUrl: string) =>
  navItems.map(m => renderItem(m, baseUrl));

const renderItem = (child: NavItem, url: string) => {
  const CurrentChild = renderLeaf(child, url);
  const CurrentTree =
    child.structure && child.structure.length
      ? renderGroup(child, makeUrl(`${url}/${child.route}`))
      : null;

  return [CurrentTree, CurrentChild];
};

const renderGroup = (item: NavItem, url: string) => {
  return item.structure && item.structure.map(child => renderLeaf(child, url));
};

const renderLeaf = (child, url): JSX.Element => {
  const ChildComponent = child.component;
  const content = (
    <BasicLayout>
      <Suspense fallback={<CenteredSpinner />}>
        <ChildComponent />
      </Suspense>
    </BasicLayout>
  );
  return (
    <PrivateRoute key={child.id} path={makeUrl(`${url}/${child.route}`)}>
      {content}
    </PrivateRoute>
  );
};
