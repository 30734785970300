import { ReactNode } from 'react';
import lazy from '../utils/lazy';
// Automation section
const AutomationPage = lazy(() => import('./Dashboard/Automation'), 'Automation');
const DialPlanPage = lazy(() => import('./DialPlans'));
const AutomatedResponsePage = lazy(
  () => import('./AutomatedResponseTemplates'),
  'AutomatedResponseTemplates'
);
const ConfusionMatricesPage = lazy(() => import('./ConfusionMatrices/root'), 'Root');
const ContextsPage = lazy(() => import('./Contexts'), 'Contexts');
// Configuration section
const ConfigurationPage = lazy(() => import('./Dashboard/Configuration'), 'Configuration');
const DialogFlowPage = lazy(() => import('./DialogflowAgents'));
const ConversationsPage = lazy(() => import('./Conversations/root'));
const TextToSpeechPage = lazy(() => import('./TextToSpeechConfigs/root'));
const KnowledgeBasesPage = lazy(() => import('./KnowledgeBases/root'));
const AutomatedChannelsPage = lazy(() => import('./AutomatedChannel'), 'AutomatedChannels');
const InboundPhoneNumbersPage = lazy(() => import('./InboundPhoneNumbers'));
const FallbackConfigPage = lazy(() => import('./FallbackConfig'));
// Routing section
const RoutingPage = lazy(() => import('./Dashboard/Routing'), 'Routing');
const RoutersPage = lazy(() => import('./Routers/root'));
const TeamsPage = lazy(() => import('./Team/root'));
const OutboundContactUris = lazy(() => import('./OutboundContactUris/root'));
// Data section
const DataPage = lazy(() => import('./Dashboard/Data'), 'Data');
const DataRetentionPolicy = lazy(() => import('./DataRetentionPolicy'), 'DataRetentionPolicy');
const DeleteConversations = lazy(() => import('./DeleteConversations/root'));
const DLPTemplatePage = lazy(() => import('./DLPTemplate'));
// Reports section
const ReportsPage = lazy(() => import('./Dashboard/Reports'), 'Reports');
const ReportsDashboardsPage = lazy(() => import('./Reports/Dashboards/root'));
const ReportsExploresPage = lazy(() => import('./Reports/Explores/root'));
const ReportsLooksPage = lazy(() => import('./Reports/Looks/root'));
const AnalyticsExternalWebhook = lazy(
  () => import('./AnalyticsWebhook'),
  'AnalyticsExternalWebhook'
);

export interface Module {
  id: string;
  title: string;
  route: string;
  component?: ReactNode;
  structure?: Module[];
}
// title must match the translation key
const AutomationSection = {
  id: 'automation',
  title: 'automation',
  route: 'automation',
  component: AutomationPage,
  structure: [
    {
      id: 'dial-plans',
      title: 'auto-profiles',
      route: 'dial-plans',
      component: DialPlanPage,
    },
    {
      id: 'automated-response-templates',
      title: 'automated-resp-temp',
      route: 'automated-response-templates',
      component: AutomatedResponsePage,
    },
    {
      id: 'confusion-matrices',
      title: 'confusion-matrices',
      route: 'confusion-matrices',
      component: ConfusionMatricesPage,
    },
    {
      id: 'contexts',
      title: 'contexts',
      route: 'contexts',
      component: ContextsPage,
    },
  ],
};
const ConfigurationSection = {
  id: 'configuration',
  title: 'configuration',
  route: 'configuration',
  component: ConfigurationPage,
  structure: [
    {
      id: 'dialogflow-agents',
      title: 'df-agents',
      route: 'dialogflow-agents',
      component: DialogFlowPage,
    },
    {
      id: 'conversations',
      title: 'convers-profiles',
      route: 'conversations',
      component: ConversationsPage,
    },
    {
      id: 'text-to-speech-configs',
      title: 'tts-profiles',
      route: 'text-to-speech-configs',
      component: TextToSpeechPage,
    },
    {
      id: 'knowledge-bases',
      title: 'kbs',
      route: 'knowledge-bases',
      component: KnowledgeBasesPage,
    },
    {
      id: 'automated-channels',
      title: 'automated-channels',
      route: 'automated-channels',
      component: AutomatedChannelsPage,
    },
    {
      id: 'inbound-phone-numbers',
      title: 'entry-points',
      route: 'inbound-phone-numbers',
      component: InboundPhoneNumbersPage,
    },
    {
      id: 'fallback-config',
      title: 'fallback-behav',
      route: 'fallback-config',
      component: FallbackConfigPage,
    },
  ],
};
const RoutingSection = {
  id: 'routing',
  title: 'routing',
  route: 'routing',
  component: RoutingPage,
  structure: [
    {
      id: 'routers',
      title: 'routers',
      route: 'routers',
      component: RoutersPage,
    },
    {
      id: 'teams',
      title: 'teams',
      route: 'teams',
      component: TeamsPage,
    },
    {
      id: 'outbound-contact-uris',
      title: 'extern-endpts',
      route: 'outbound-contact-uris',
      component: OutboundContactUris,
    },
  ],
};

const DataSection = {
  id: 'data',
  title: 'data',
  route: 'data',
  component: DataPage,
  structure: [
    {
      id: 'delete-conversations',
      title: 'delete-convo',
      route: 'delete-conversations',
      component: DeleteConversations,
    },
    {
      id: 'data-retention-policy',
      title: 'data-ret-pol',
      route: 'data-retention-policy',
      component: DataRetentionPolicy,
    },
    {
      id: 'analytics-external-webhook',
      title: 'analytics-ext-webhook-title',
      route: 'analytics-external-webhook',
      component: AnalyticsExternalWebhook,
    },
    {
      id: 'dlp-template',
      title: 'dlp-template-title',
      route: 'dlp-template',
      component: DLPTemplatePage,
    },
  ],
};

const ReportsSection = {
  id: 'reports',
  title: 'reports',
  route: 'reports',
  structure: [
    {
      id: 'looker-dashboards',
      title: 'dashboards',
      route: 'looker-dashboards',
      component: ReportsDashboardsPage,
    },
    {
      id: 'looker-explores',
      title: 'explores',
      route: 'looker-explores',
      component: ReportsExploresPage,
    },
    {
      id: 'looker-looks',
      title: 'looks',
      route: 'looker-looks',
      component: ReportsLooksPage,
    },
  ],
  component: ReportsPage,
};

export const NavigationModules = [
  AutomationSection,
  ConfigurationSection,
  RoutingSection,
  DataSection,
  ReportsSection,
];

export const findModuleObject = (givenRoute: string): { section: Module; module: Module } => {
  const segments = givenRoute.split('/');
  const categoryRoute = segments[1];
  const moduleRoute = segments[2];
  const section = findCategory(categoryRoute)[0];
  const module = moduleRoute ? findModule(section, moduleRoute) : {};
  return { section, module } as { section: Module; module: Module };
};

export const findCategory = (givenRoute: string): Module[] =>
  NavigationModules.filter(item => item.route === givenRoute);

export const findModule = (category: Module, givenRoute: string): Module | undefined => {
  if (!category.structure || category.structure.length === 0) {
    return undefined;
  }
  return category.structure.find(item => item.route === givenRoute);
};
