import React, { Suspense } from 'react';
import { Alert, Col, Row } from 'antd';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import zoid from 'zoid';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ReactQueryDevtools } from 'react-query-devtools';
import { ReactQueryConfigProvider } from 'react-query';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { App } from './core/App';
import {
  setAuthConfiguration,
  getCredentials,
  IConfiguration,
  ICustomWindowObject,
} from './utils/auth';
import { ProjectService } from './services/projects';
import { CustomHistoryProvider } from './core/HistoryContext';
import { AccountProvider } from './core/AccountContext';
import './styles/main.css';
import i18n from './locales/i18n';
import { Locale } from './locales/i18n.d';
import { CenteredSpinner } from './common/components/utils/Spinner';
import { getHeader } from './utils/getHeader';
import { parseJwt } from './utils/parseJwt';

declare const window: ICustomWindowObject;

const errorMessage =
  'Auth token or projectId are not specified. Please provide a valid token\n or projectId';

const {
  NODE_ENV,
  REACT_APP_SENTRY_DSN,
  REACT_APP_API_ENDPOINT,
  REACT_APP_NAME,
  REACT_APP_VERSION,
} = process.env;

if (NODE_ENV !== 'development' && REACT_APP_SENTRY_DSN) {
  const env = REACT_APP_API_ENDPOINT?.includes('staging') ? 'staging' : NODE_ENV;
  const releaseId = `${REACT_APP_NAME}@${REACT_APP_VERSION}`;
  Sentry.init({ dsn: REACT_APP_SENTRY_DSN, environment: env, release: releaseId });
}

export const configBase: IConfiguration = {
  sso_token: '',
  account_sid: '',
  projectId: '',
  authToken: '',
  authProjectList: '',
};

const redirectToAdBlockPage = () => {
  const history = createBrowserHistory();
  return history.replace('/ad-blocker-warning');
};

const checkLocalStorage = () => {
  try {
    window.localStorage.setItem('isWritable', 'true');
    window.localStorage.removeItem('isWritable');
  } catch {
    return redirectToAdBlockPage();
  }
};
/* This tag should be the same as the plugin-airline-manager tag
   and since we need two versions of these plugins, they must be different
 */
const generateLabelTag = (endpoint?: string): string =>
  endpoint?.includes('beta') ? 'admin-page-beta' : 'admin-page';

zoid.create({
  tag: generateLabelTag(REACT_APP_API_ENDPOINT),
  props: {
    sso_token: {
      type: 'string',
      required: true,
    },
    account_sid: {
      type: 'string',
      required: true,
    },
    locale: {
      type: 'string',
      required: true,
    },
  },
});

(async function (containerId, configOverrides: ICustomWindowObject): Promise<void | JSX.Element> {
  const credentials = getCredentials(configOverrides);
  const config = Object.assign({}, configBase, credentials);
  const { sso_token, account_sid, projectId, authToken, authProjectList } = config;
  const { search } = configOverrides.location;
  if (search) {
    const locale = getHeader(search, 'locale') as Locale;
    locale && (await i18n.changeLanguage(locale));
  }

  const consoleAccountId = getHeader(search, 'Console-account-id');
  const consoleProjectId = getHeader(search, 'Console-project-id');
  const consoleAccountName = getHeader(search, 'Console-account-name');
  const consoleProjectName = getHeader(search, 'Console-project-name');

  const parsedToken = parseJwt(authToken);

  if (!config.sso_token && !config.projectId) {
    console.error(errorMessage);
    const error = (
      <Row>
        <Col span={8} offset={8} style={{ marginTop: 50 }}>
          <Alert message={errorMessage} type="error" />
        </Col>
      </Row>
    );
    const errorContainer = document.getElementById(containerId);
    const root = createRoot(errorContainer!);
    root.render(error);
    return;
  }

  let isNewAccount;
  let isCCAIEnabled;

  try {
    checkLocalStorage();
    setAuthConfiguration({ sso_token, account_sid, projectId, authToken, authProjectList });
    // Checks for an existing account by account_sid or projectId
    const { project, error } = await ProjectService.get(account_sid || projectId);
    isNewAccount = !!(error && Array.isArray(error.data));
    isCCAIEnabled = project && project.is_ccai_enabled;
  } catch {
    console.error(i18n.t('cannot-check-new-account'));
  }
  const queryConfig = { queries: { refetchOnWindowFocus: false, cacheTime: 5 * 60 * 1000 } };

  const initialLDContext = {
    kind: 'multi',
    user: {
      anonymous: false,
      key: parsedToken?.preferred_username,
      name: parsedToken?.name,
    },
    account: {
      anonymous: false,
      key: consoleAccountId,
      name: consoleAccountName,
    },
    project: {
      name: consoleProjectName,
      anonymous: false,
      key: consoleProjectId,
      account: {
        key: consoleAccountId,
        name: consoleAccountName,
      },
    },
  };

  const clientId = process.env.REACT_APP_FEATURE_FLAG_CLIENTSIDE_ID;
  const LDProvider = await asyncWithLDProvider({
    clientSideID: clientId!,
    context: initialLDContext,
  });
  const content = (
    <LDProvider>
      <Suspense fallback={<CenteredSpinner />}>
        <Router>
          <ReactQueryConfigProvider config={queryConfig}>
            <CustomHistoryProvider>
              <AccountProvider accountConfiguration={{ isNewAccount, isCCAIEnabled }}>
                <App />
              </AccountProvider>
            </CustomHistoryProvider>
          </ReactQueryConfigProvider>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </Suspense>
    </LDProvider>
  );
  const container = document.getElementById(containerId);
  const root = createRoot(container!);
  root.render(content);
})('root', window);
