import React from 'react';
import * as R from 'ramda';
import { Crumb } from './types';
import { BreadcrumbsContext } from './BreadcrumbsContext';
import { findModuleObject } from '../../../../modules/modules';

interface State {
  crumbs: Crumb[];
}

export class BreadcrumbsProvider extends React.PureComponent<unknown, State> {
  state: State = {
    crumbs: [],
  };

  attach = (route: string, title: string): void => {
    const { crumbs } = this.state;
    const routesArray: Crumb[] = [];
    const {
      section: { route: categoryRoute, title: categoryTitle },
      module: { route: moduleRoute, title: moduleTitle },
    } = findModuleObject(route);

    routesArray.push({ route: `/${categoryRoute}`, title: categoryTitle });
    if (moduleRoute && route !== `/${categoryRoute}/${moduleRoute}`) {
      routesArray.push({ route: `/${categoryRoute}/${moduleRoute}`, title: moduleTitle });
    }
    routesArray.push({ route, title });
    const sortedCrumbs = sortCrumbs([...crumbs, ...routesArray]);
    const righteousRoutes = rightRoutes(sortedCrumbs, route);

    this.setState(() => ({
      crumbs: righteousRoutes,
    }));
  };

  update = (path: string): void => {
    const { crumbs } = this.state;
    const righteousRoutes = rightRoutes(crumbs, path);
    this.setState(() => ({ crumbs: righteousRoutes }));
  };

  reset = (): void => {
    this.setState(() => ({ crumbs: [] }));
  };

  render(): JSX.Element {
    const { children } = this.props;
    const { crumbs } = this.state;

    const context = {
      crumbs,
      attach: this.attach,
      update: this.update,
      reset: this.reset,
    };

    return <BreadcrumbsContext.Provider value={context}>{children}</BreadcrumbsContext.Provider>;
  }
}

const sortCrumbs = (crumbs: Crumb[]): Crumb[] => {
  const crumbList = crumbs.sort((a, b) => a.route.length - b.route.length);
  return R.uniqBy(R.prop('route'), crumbList);
};

const rightRoutes = (crumbs: Crumb[], givenRoute: string) =>
  crumbs.filter(item => givenRoute.indexOf(item.route) !== -1);
