import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Add } from './icons/add.svg';
import { ReactComponent as AgentAssist } from './icons/iconAssist.svg';
import { ReactComponent as AutoProfileRobot } from './icons/auto-profile-robot.svg';
import { ReactComponent as Report } from './icons/iconReport.svg';
import { ReactComponent as Chart } from './icons/automation-chart.svg';
import { ReactComponent as Check } from './icons/check.svg';
import { ReactComponent as ComingSoon } from './icons/coming-soon.svg';
import { ReactComponent as Data } from './icons/iconData.svg';
import { ReactComponent as Drag } from './icons/drag.svg';
import { ReactComponent as Edit } from './icons/pen.svg';
import { ReactComponent as HomeConfig } from './icons/home-config.svg';
import { ReactComponent as HomeRouting } from './icons/home-routing.svg';
import { ReactComponent as Analyze } from './icons/iconAnalyze.svg';
import { ReactComponent as Robot } from './icons/iconConversationAI.svg';
import { ReactComponent as Routing } from './icons/iconRouting.svg';
import { ReactComponent as Automation } from './icons/iconAuto.svg';
import { ReactComponent as Configuration } from './icons/iconConfig.svg';
import { ReactComponent as Cross } from './icons/times.svg';
import { ReactComponent as Trash } from './icons/trash.svg';
import { ReactComponent as TrashSolid } from './icons/trash-alt.svg';
import { ReactComponent as ChevronLeft } from './icons/chevron-double-left.svg';
import { ReactComponent as ChevronRight } from './icons/chevron-double-right.svg';
import { ReactComponent as Upload } from './icons/upload.svg';
import { ReactComponent as Minus } from './icons/minus.svg';

const icons = {
  add: Add,
  'agent-assist': AgentAssist,
  'auto-profile-robot': AutoProfileRobot,
  automation: Automation,
  analyze: Analyze,
  chart: Chart,
  check: Check,
  configuration: Configuration,
  'coming-soon': ComingSoon,
  cross: Cross,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  data: Data,
  drag: Drag,
  edit: Edit,
  'home-config': HomeConfig,
  'home-routing': HomeRouting,
  report: Report,
  robot: Robot,
  routing: Routing,
  trash: Trash,
  'trash-solid': TrashSolid,
  upload: Upload,
  minus: Minus,
};

type IconName =
  | 'add'
  | 'agent-assist'
  | 'auto-profile-robot'
  | 'automation'
  | 'analyze'
  | 'chart'
  | 'check'
  | 'coming-soon'
  | 'configuration'
  | 'cross'
  | 'chevronLeft'
  | 'chevronRight'
  | 'data'
  | 'drag'
  | 'edit'
  | 'home-config'
  | 'home-routing'
  | 'minus'
  | 'report'
  | 'robot'
  | 'routing'
  | 'upload'
  | 'trash'
  | 'trash-solid';

interface Props {
  name: IconName;
  width?: number;
  height?: number;
}

const Icon: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { name, width = 16, height = 16 } = props;

  if (!icons[name]) {
    throw new Error(t('icon-missing', { name }));
  }

  const IconComponent = icons[name];

  return <IconComponent width={width} height={height} />;
};

export default Icon;
