import React from 'react';
import styled, { CSSObject } from 'styled-components';

interface FlagProps {
  text?: string;
  styles?: CSSObject;
}

export const Flag = ({ text, styles }: FlagProps): JSX.Element => {
  return <Root styles={styles}>{text}</Root>;
};

interface RootProps {
  styles?: CSSObject;
}

const Root = styled.div<RootProps>`
  position: absolute;
  top: 50%;
  right: 0;
  padding: 5px 8px;
  transform: translateY(-50%);
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  ${props => props.styles};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.flagBg};
    transform: skewX(-12deg);
    z-index: -1;
  }
`;
