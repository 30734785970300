type ParsedToken = {
  preferred_username?: string;
  name?: string;
  [key: string]: any;
};

export const parseJwt = (token: string): ParsedToken | null => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
