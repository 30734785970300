import axios, { CancelToken } from 'axios';
import { getAuthConfiguration } from '../utils/auth';

export interface QueryOptions {
  method?: 'GET' | 'PUT' | 'PATCH' | 'POST' | 'DELETE';
  data?: any;
  headers?: any;
  cancelToken?: CancelToken;
  params?: any;
}

interface Error {
  error: {
    statusCode: string;
    data: string;
    message: string;
  };
}

export const query: (url: string, options?: QueryOptions) => Promise<any> = async (
  url: string,
  options: QueryOptions = {}
): Promise<any | Error> => {
  const { method = 'GET', data = null, cancelToken, params = null } = options;
  const { sso_token, account_sid, projectId, authToken, authProjectList } = getAuthConfiguration();
  const headers = options.headers || {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
  };

  Object.assign(headers, {
    ...(sso_token && { Authorization: `Bearer ${sso_token}` }),
    ...(account_sid && { TWILIO_ACCOUNT_SID: account_sid }),
    ...(projectId && { PROJECT_ID: projectId }),
    ...(authToken && { AUTH_TOKEN: authToken }),
    ...(authProjectList && { AUTH_PROJECT_LIST: authProjectList }),
  });

  const config = {
    url: process.env.REACT_APP_API_ENDPOINT + url,
    method,
    headers,
    data,
    cancelToken,
    params,
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (e) {
    return {
      error: {
        statusCode: e.response?.status,
        data: e.response?.data.errors,
        message: e.response?.data.error || e.message,
      },
    };
  }
};

// Works with react-query hooks
export const cancelableQuery: (asyncFn) => (...args) => any = asyncFn => (...args) => {
  const source = axios.CancelToken.source();
  const config = {
    cancelToken: source.token,
  };
  const promise = asyncFn(...args, config);
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };
  return promise;
};
