import { configBase } from '../index';
import { getHeader } from './getHeader';

export const AUTH_CONFIGURATION_KEY = 'AUTH_CONFIGURATION';

export interface IConfiguration {
  sso_token: string;
  account_sid: string;
  projectId: string;
  authToken: string;
  authProjectList: string;
}

export interface ICustomWindowObject extends Window {
  xprops: {
    [key: string]: string;
  };
}

interface IFlexCreds {
  sso_token: string;
  account_sid: string;
}

interface IConsoleCreds {
  projectId: string;
  authToken: string;
  authProjectList: string;
}

export const getCredentials = (data: ICustomWindowObject): IFlexCreds | IConsoleCreds | null => {
  if (data.xprops) {
    const { sso_token, account_sid } = data.xprops;
    return { sso_token, account_sid };
  }
  if (data.location.search) {
    const { search } = data.location;
    const projectId = getHeader(search, 'projectId');
    const authToken = getHeader(search, 'auth');
    const authProjectList = getHeader(search, 'authproject');

    if (projectId && authToken && authProjectList) {
      return { projectId, authToken, authProjectList };
    }
    return null;
  }
  return null;
};

export function setAuthConfiguration(config: IConfiguration): void {
  const data = JSON.stringify(config);
  window.localStorage.setItem(AUTH_CONFIGURATION_KEY, data);
}

export function getAuthConfiguration(): IConfiguration {
  const data = window.localStorage.getItem(AUTH_CONFIGURATION_KEY);
  if (!data) {
    console.error('Auth configuration object is missing');
    return configBase;
  }
  return JSON.parse(data);
}
