import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export const Spinner = (): JSX.Element => <Spin indicator={antIcon} />;

Spin.setDefaultIndicator(antIcon);

export const CenteredSpinner = (): JSX.Element => (
  <SpinnerWrapper>
    <Spinner />
  </SpinnerWrapper>
);

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;
