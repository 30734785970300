import { query } from './api';
import { ApiResponse } from '../hooks/useCreate';
import { FormValues } from '../modules/Accounts/AccountForm';

const create = (data: FormValues): Promise<ApiResponse> =>
  query(`/projects`, {
    method: 'POST',
    data: { data: { type: 'projects', attributes: data } },
  });

const get = (account_sid: string): Promise<ApiResponse> =>
  query(`/projects/${account_sid}`, {
    method: 'GET',
  });

export const ProjectService = { create, get };
