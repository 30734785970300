import * as React from 'react';
import styled, { css, StyledComponent } from 'styled-components';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

export enum ButtonTheme {
  primary,
  secondary,
  outline,
  negative,
}

export enum ButtonVariant {
  contained,
  text,
  icon,
  delete,
}

export interface ButtonProps {
  children: string | JSX.Element | JSX.Element[];

  icon?: JSX.Element;
  buttonType?: 'button' | 'submit' | 'reset';
  href?: string;
  className?: string;
  variant?: ButtonVariant;
  theme?: ButtonTheme;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = props => {
  const {
    children,
    href = '',
    icon = null,
    buttonType = 'button',
    theme = ButtonTheme.primary,
    variant = ButtonVariant.contained,
    loading = false,
    ...rest
  } = props;

  const isLink = !!href;

  const Tag = isLink ? LinkTag : ButtonTag;

  const componentProps = isLink
    ? {
        to: href,
      }
    : {
        type: buttonType,
        isLoading: loading,
      };

  return (
    <Tag {...componentProps} button_theme={theme} button_variant={variant} {...rest}>
      {loading && <LoadingOutlined />}
      {icon && !loading && <Icon>{icon}</Icon>}
      {children}
    </Tag>
  );
};

interface ButtonCssProps {
  button_theme: ButtonTheme;
  button_variant: ButtonVariant;
  disabled?: boolean;
  isLoading?: boolean;
}

const commonButtonCss = css<ButtonCssProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  border-radius: ${props => props.theme.borderRadiusBase};
  box-shadow: ${props => props.theme.inputShadow};
  font-size: ${props => props.theme.fontSizeBase};
  line-height: 20px;
  font-weight: 600;
  font-family: ${props => props.theme.fontFamilyTitle};
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease;
  padding: 8px 16px;
  height: ${props => props.theme.inputHeightBase};

  &[disabled] {
    background-color: ${props => props.theme.disabledBg};
    color: ${props => props.theme.disabledColorBtn};
  }

  .anticon {
    margin-right: 5px;
  }
  .anticon-spin {
    width: 13px;
  }

  ${props =>
    props.button_theme === ButtonTheme.primary &&
    css`
      background-color: ${props => props.theme.primaryBgColor};
      color: ${props => props.theme.primaryColor};

      &:hover {
        background-color: ${props => props.theme.primaryHoverBgColor};
        color: #fff;
      }
    `};

  ${props =>
    props.button_theme === ButtonTheme.secondary &&
    css`
      background-color: ${props => props.theme.secondaryBgColor};
      color: ${props => props.theme.secondaryColor};

      &:hover {
        background-color: ${props => props.theme.secondaryHoverBgColor};
        color: ${props => props.theme.secondaryHoverColor};
      }
    `};

  ${props =>
    props.button_theme === ButtonTheme.negative &&
    css`
      background-color: ${props => props.theme.errorColor};
      color: #fff;

      &:hover {
        background-color: ${props => props.theme.primaryHoverBgColor};
        color: #fff;
      }
    `};

  ${props =>
    props.button_theme === ButtonTheme.outline &&
    css`
      background-color: ${props => props.theme.outlineBgColor};
      color: ${props => props.theme.outlineColor};

      &:hover {
        background-color: ${props => props.theme.outlineHoverBgColor};
        color: ${props => props.theme.outlineColor};
      }
    `};
  ${props =>
    props.button_variant === ButtonVariant.delete &&
    css`
      border: 1px solid ${props => props.theme.borderColorBase};
      background-color: ${props => props.theme.white};
      color: ${props => props.theme.outlineColor};
      font-weight: 500;
      &:hover {
        border: 1px solid #3b91db;
        background-color: ${props => props.theme.white};
        color: #3b91db;
      }
    `};

  ${props =>
    props.button_variant === ButtonVariant.icon &&
    css`
      border: 0 none;
      background: transparent;
      box-shadow: none;
      color: #05081d;

      &:hover {
        background-color: transparent;
        color: #05081d;
      }
    `};

  ${props =>
    (props.disabled || props.isLoading) &&
    css`
      pointer-events: none;
    `};
`;

const ButtonTag = styled.button`
  padding: 0;
  border: none;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }

  ${commonButtonCss};
`;

const LinkTag = (styled(Link)`
  text-decoration: none;

  ${commonButtonCss};
` as any) as StyledComponent<'link', any, ButtonCssProps, any>;

const Icon = styled.div`
  margin-right: 5px;

  svg {
    display: block;
    width: 13px;
    height: 14px;
    fill: currentColor;
  }
`;

export default Button;
