import { lazy, ComponentType, LazyExoticComponent } from 'react';

export default <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }> | Promise<{ [key: string]: ComponentType<any> }>,
  name = 'default'
): LazyExoticComponent<T> => {
  return lazy(async () => {
    const resolved = await factory();
    return { default: resolved[name] };
  });
};
