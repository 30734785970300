import styled from 'styled-components';

export const InlineGroup = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;

  & > * {
    margin-left: 20px;
    margin-right: 20px;
  }
`;
