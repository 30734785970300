import React, { useContext, useState } from 'react';

type AccountConfiguration = { isNewAccount: boolean; isCCAIEnabled: boolean };
export interface AccountContextInterface {
  config: AccountConfiguration;
  setAccountConfig: (accountConfiguration: AccountConfiguration) => void;
}

export const AccountContext = React.createContext<AccountContextInterface | null>(null);
export const useAccountContext = (): AccountContextInterface =>
  useContext(AccountContext) as AccountContextInterface;

export const AccountProvider: React.FC<{ accountConfiguration: AccountConfiguration }> = ({
  accountConfiguration,
  children,
}) => {
  const [config, setAccountConfig] = useState<AccountConfiguration>(accountConfiguration);

  return (
    <AccountContext.Provider value={{ config, setAccountConfig }}>
      {children}
    </AccountContext.Provider>
  );
};
