type ConsoleHeaders =
  | 'projectId'
  | 'auth'
  | 'authproject'
  | 'locale'
  | 'Console-project-id'
  | 'Console-account-id'
  | 'Console-project-name'
  | 'Console-account-name';

export const getHeader = (source: string, name: ConsoleHeaders): string | null => {
  return new URLSearchParams(source).get(name);
};
