import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { ISupportedLanguage } from './i18n.d';

export const supportedLanguages: ISupportedLanguage[] = [
  {
    lng: 'English',
    locale: 'en-GB',
  },
  {
    lng: 'Español',
    locale: 'es-ES',
  },
  {
    lng: 'Nederlands',
    locale: 'nl-NL',
  },
  {
    lng: 'Français',
    locale: 'fr-FR',
  },
  {
    lng: 'Danish',
    locale: 'da-DK',
  },
  {
    lng: 'Japanese',
    locale: 'ja-JP',
  },
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    fallbackLng: 'en',
    debug: false,
    load: 'languageOnly',
    returnNull: false,
  });

export default i18n;
