import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { InlineGroup } from '../InlineGroup';
import Icon from '../Icon';

export const SubForm = styled.div`
  max-width: 760px;
  padding: 20px 16px 0;
  border: 1px solid ${props => props.theme.borderColorBase};
  border-radius: ${props => props.theme.borderRadiusBase};
  background-color: #fff;
  margin: 4px 0 20px;

  h3 {
    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.textColor};
    & + p {
      margin-top: -5px;
      margin-bottom: 20px;
    }
  }

  .ant-tabs {
    margin-bottom: 30px;
  }

  .subFormToggle {
    margin-top: -10px;
    &:last-child {
      margin-bottom: 15px;
    }
  }

  ${InlineGroup} {
    flex-wrap: nowrap;
  }

  ${InlineGroup} .ant-form-item-btn {
    margin-left: 0;
  }
`;
interface SubFormToggleProps {
  name: string;
  isOpened: boolean;
  onClick?: () => void;
}

interface SubFormLinkProps {
  isDelete?: boolean;
}
export const SubFormLink = styled('a')<SubFormLinkProps>`
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
  line-height: 0.9;
  font-weight: 600;
  font-family: ${props => props.theme.fontFamilyTitle};
  color: ${props => (props.isDelete ? props.theme.errorColor : props.theme.linkColor)};
  text-transform: uppercase;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  &:hover {
    color: ${props => (props.isDelete ? props.theme.errorColor : props.theme.linkColor)};
    border-bottom-color: ${props =>
      props.isDelete ? props.theme.errorColor : props.theme.linkColor};
  }
  svg {
    width: 13px;
    height: 14px;
    margin-right: 5px;
  }
`;

export const SubFormTitle = styled('div')`
  margin-bottom: 15px;
`;

export const SubFormToggle: React.FC<SubFormToggleProps> = ({ name, isOpened, onClick }) => {
  const { t } = useTranslation();
  return (
    <Root className="subFormToggle">
      <SubFormLink onClick={onClick} isDelete={isOpened}>
        {isOpened ? (
          <>
            <Icon name="trash-solid" /> {`${t('remove')} ${name}`}
          </>
        ) : (
          <>
            <Icon name="add" /> {`${t('add')} ${name}`}
          </>
        )}
      </SubFormLink>
    </Root>
  );
};

export const Root = styled('div')`
  margin-bottom: 10px;

  ${SubFormLink} {
    margin-bottom: 0;
    &:hover {
      border-bottom: transparent;
    }
  }
`;
