import { getAuthConfiguration } from './auth';

type GetPlatformType = {
  platform: 'flex' | 'console';
};

export const getPlatformName = (): GetPlatformType => {
  const { sso_token, account_sid } = getAuthConfiguration();
  if (sso_token?.trim() || account_sid?.trim()) {
    return { platform: 'flex' };
  }
  return { platform: 'console' };
};
