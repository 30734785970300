import { createGlobalStyle } from 'styled-components';
import { formItemClass, theme } from '../theme';
import { SubForm } from '../common/components/utils/SubForm';

export const Stylesheet = createGlobalStyle`
  body {
    overflow-y: scroll;
  }
  svg {
    fill: currentColor;
  }
  svg:not([data-icon]) {
    vertical-align: middle;
  }
  #root {
    min-height: 100%;
    background-color: ${theme.bgColor};
  }
  .mainContent {
    display: flex;
    flex-direction: column;
  }
  .mainContent > div {
    flex: 1 1 auto;
    position: relative;
    z-index: 1;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: auto 80%;
      background-position: right 60%;
      background-attachment: fixed;
      z-index: -1;
    }
  }
  .mainContent > div:not(.dashboard):after {
    opacity: 0.3;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.fontFamilyTitle};
    font-weight: 600;
  }
  h1 {
    text-transform: uppercase;
  }
  h2 {
    font-size: ${theme.headingSize_2};
    line-height: 1.5;
    text-transform: uppercase;
  }
  h3 {
    font-size: ${theme.headingSize_3};
    line-height: 1.4;
  }
  .ant-form-item {
    max-width: 100%;
  }
  .${formItemClass.base} {
    width: 360px;
  }
  .${formItemClass.small} {
    width: 304px;
  }
  .${formItemClass.xsmall} {
    width: 160px;
  }
  .${formItemClass.full} {
    width: 760px;
  }
  ${SubForm} {
    .${formItemClass.base} {
      max-width: 304px;
    }
  }
  .ant-modal-body {
    .${formItemClass.base} {
      max-width: 304px;
    }
    ${SubForm} {
      .${formItemClass.base} {
        max-width: 258px;
      }
      .${formItemClass.small} {
        max-width: 258px;
      }
    }
    .ant-upload-list-item {
      max-width: 304px;
    }
  }
  .ant-upload-list-item {
    max-width: 360px;
    &:hover .ant-upload-list-item-info {
      background-color: ${theme.disabledBg};
    }
  }
  .ant-input {
    outline: 1px solid transparent;
  }
  .ant-input[disabled],
  .ant-input[disabled]:hover,
  .ant-picker.ant-picker-disabled {
    border-color: ${theme.disabledBg};
  }
  .ant-input:focus, 
  .ant-input-focused,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker-focused {
    box-shadow: none;
    outline: 1px solid ${theme.primaryColor};
  }
  .ant-form-item-has-error .ant-input:focus, 
  .ant-form-item-has-error .ant-input-affix-wrapper:focus, 
  .ant-form-item-has-error .ant-input-focused, 
  .ant-form-item-has-error .ant-input-affix-wrapper-focused,
  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
    box-shadow: none;
    outline: 1px solid ${theme.errorColor};
  }
  .ant-select-item {
    padding: 6px 16px;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-tag {
    border-color: #fff;
    color: #fff;
    background-color: transparent;
  }
  .ant-form-item-label > label .ant-form-item-tooltip {
    color: ${theme.secondaryBgColor};
  }
  .ant-form-vertical .ant-form-item-label {
    line-height: ${theme.labelLineHeight};
    font-weight: 600;
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    min-height: 15px;
    margin-top: 3px;
    font-size: 12px;
    line-height: 1.2;
    white-space: pre-line;
  }
  .ant-form-item-extra,
  .ant-form-item-has-extra .ant-form-item-explain {
    min-height: 0;
  }
  .ant-form .ant-form-item-with-help {
    margin-bottom: 2px;
  }
  .ant-form .ant-form-item-with-help.ant-form-item-has-extra {
    margin-bottom: ${theme.fieldBottomMargin};
  }
  .ant-form-item-btn {
    align-self: flex-end;
    button {
      width: 36px;
      min-width: 0;
      padding: 8px;
    }
  }
  .ant-pagination-options-size-changer.ant-select .ant-select-selector {
    height: 32px;
  }
  .ant-pagination-options-size-changer.ant-select .ant-select-selection-item {
    line-height: 30px;
  }
  .ant-select .ant-select-selection__rendered {
    margin-left: 19px;
    margin-right: 19px;
  }
  .ant-select-arrow {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${theme.primaryColor};
    transition: transform .3s;
  }
  .ant-select-open .ant-select-arrow {
    transform: rotate(180deg);
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: transparent;
    .ant-tag {
      opacity: 0.2;
    }
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-arrow {
    color: #929296;
  }
  .ant-select-dropdown-menu {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .ant-select-dropdown .ant-select-dropdown-menu-item {
    padding: 10px 20px;
  }
  .ant-select-dropdown .ant-select-dropdown-menu-item-active,
  .ant-select-dropdown .ant-select-dropdown-menu-item:hover {
    background-color: #f8f8f8;
  }
  .ant-select-dropdown .ant-select-dropdown-menu-item-selected,
  .ant-select-dropdown .ant-select-dropdown-menu-item-selected:hover {
    background-color: #f8f8f8;
    color: ${theme.primaryColor};
    font-weight: 400;
  }
  .ant-select-multiple .ant-select-selection-item {
    border: 0 none;
    background-color: ${theme.secondaryBgColor};
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner:hover {
    background-color: ${theme.primaryColor};
    color: #fff;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
    background-color: ${theme.secondaryBgColor};
  }
  .ant-form-item .ant-input-group .ant-select {
    width: 100% !important;
  }
  .ant-input-group.ant-input-group-compact .ant-select .ant-select-arrow,
  .ant-input-group.ant-input-group-compact .ant-select-focused,
  .ant-input-group.ant-input-group-compact .ant-select .ant-select-selector:focus,
  .ant-input-group.ant-input-group-compact .ant-select .ant-select-selector:hover,
  .ant-input-group.ant-input-group-compact .ant-form-item-has-error .ant-select:not(.ant-select-disabled) .ant-select-selector {
    z-index: 1;
  }
  .ant-form-item .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form .ant-form-item.boxed,
  .ant-form .ant-form-item.boxedBorder {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${theme.borderColorAdditional};
    border-radius: ${theme.borderRadiusBase};
    padding: 0 12px 0 16px;
    margin-bottom: 10px;
    .ant-form-item-label {
      padding-left: 0;
      padding-bottom: 0;
      text-transform: none;
      letter-spacing: normal;
      label {
        font-size: 14px;
        font-weight: 400;
        color: #000;
      }
    }
    .ant-form-item-control {
      flex-grow: 0;
    }
  }
  .ant-form-item.boxed {
    background-color: #fff;
  }
  .ant-form-item.boxedBorder {
    border: 1px solid ${theme.borderColorBase};
  }
  .ant-form-item.radioBoxed {
    display: flex;
    flex-direction: column;
    border-radius: ${theme.borderRadiusBase};
    padding: 16px 15px 17px 20px;
    background-color: #fff;
    .ant-form-item-label {
      padding-left: 0;
      padding-right: 0;
    }
    .ant-form-item-control-input {
      min-height: 0;
      line-height: 1.4;
    }
  }
  .ant-slider .ant-slider-handle {
    width: 16px;
    height: 16px;
    background-color: ${theme.primaryColor};
  }
  .ant-slider .ant-slider-handle:focus {
    box-shadow: none;
  }
  .slider-tooltip .slider-tooltip.ant-tooltip-inner {
    min-width: 30px;
    min-height: 20px;
    padding: 4px 7px;
    background-color: ${theme.primaryColor};
    line-height: 1;
    font-weight: 600;
    text-align: center;
    box-shadow: none;
  }
  .slider-tooltip.ant-tooltip {
    z-index: 1030;
  }
  .slider-tooltip.ant-tooltip-placement-top {
    padding-bottom: 0;
  }
  .slider-tooltip.ant-tooltip-arrow {
    display: none;
  }
  .ant-time-picker-panel-input {
    height: 41px;
  }
  .ant-pagination-item {
    font-weight: 600;
  }
  .ant-pagination-item:not(.ant-pagination-item-active):focus-visible, 
  .ant-pagination-item:not(.ant-pagination-item-active):hover,
  .ant-pagination-prev:focus-visible .ant-pagination-item-link, 
  .ant-pagination-next:focus-visible .ant-pagination-item-link, 
  .ant-pagination-prev:hover .ant-pagination-item-link, 
  .ant-pagination-next:hover .ant-pagination-item-link {
    background-color: ${theme.paginationHoverBg};
  }
  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link, 
  .ant-pagination-next .ant-pagination-item-link {
    border: 0 none;
  }
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    background-color: inherit;
  }
  .ant-pagination-item a {
    color: ${theme.primaryColor};
  }
  .ant-tabs {
    border: 1px solid ${theme.borderColorAdditional};
    border-radius: ${theme.borderRadiusBase};
    background-color: ${theme.bgColor};
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom-color: ${theme.borderColorAdditional};
  }
  .ant-tabs .ant-tabs-nav-wrap {
    padding: 0 16px;
  }
  .ant-tabs .ant-tabs-nav {
    margin-bottom: 0;
    background-color: #fff;
  }
  .ant-tabs-tab {
    font-weight: 600;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
  }
  .ant-tabs .ant-tabs-tabpane {
    padding: 16px 16px 6px;
  }
  .ant-radio-group .ant-radio-wrapper {
    margin-right: 25px;
  }
  .ant-radio-button-wrapper {
    background-color: #fff;
  }
  .ant-radio-button-wrapper-disabled {
    background-color: #f2f2fa;
  }
  .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    outline: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: #ffffff;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
  }
  .ant-radio-button-wrapper-disabled,
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper-disabled:hover,
  .ant-radio-button-wrapper-disabled:first-child {
    border-color: ${theme.disabledBg};
  }
  .ant-radio-button-wrapper-disabled:not(:first-child)::before {
    background-color: ${theme.disabledBg};
  }
  .ant-modal {
    max-width: 100%;
  }
  .ant-modal .ant-modal-title {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    font-family: ${theme.fontFamilyTitle};
    svg {
      margin-right: 7px;
    }
  }
  .ant-modal-footer button+button {
    margin-left: 8px;
  }
  .ant-popover-inner {
    width: 340px;
  }
  audio {
    display: block;
    width: 100%;
    max-width: 260px;
    min-width: 220px;
    height: 48px;
  }
  .ant-tooltip .ant-tooltip-inner {
    padding: 10px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
  }
  .ant-tooltip.ant-tooltip-light {
    max-width: 260px;
  }
  .ant-tooltip-light .ant-tooltip-inner {
    background-color: ${theme.white};
    color: ${theme.black};
  }
  .ant-tooltip-light .ant-tooltip-arrow::before {
    background-color: ${theme.white};
  }
  .ant-notification-notice {
    border-radius: 2px;
  }
  .ant-notification-notice-message {
    color: ${theme.textColor};
  }
  .ant-notification-notice-with-icon .ant-notification-notice-message,
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 34px;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: ${theme.fontSizeBase};
    line-height: 1.3;
    margin-bottom: 0;
  }
  .ant-notification-notice .anticon svg,
  .ant-notification-notice-close svg {
    display: block;
  }
  .ant-notification-notice-success {
    background-color: #e8f7e8;
    border: 1px solid ${theme.successColor};
  }
  .ant-notification-notice-error {
    background-color: #ffebeb;
    border: 1px solid ${theme.errorColor};
  }
  .ant-notification-notice-warning {
    background-color: #fdfbe6;
    border: 1px solid #f4d70e;
  }
  .ant-notification-notice-icon {
    margin-left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .ant-notification-notice-close {
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: ${theme.textColor};
  }
  .ant-ribbon {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: transparent;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${theme.flagBg};
      transform: skewX(-12deg);
      z-index: -1;
    }
  }
  .ant-ribbon-corner {
    display: none;
  }
  .ant-ribbon.ant-ribbon-placement-end {
    top: 50%;
    left: 100%;
    right: auto;
    transform: translate(-10px, -50%);
  }
`;
