const theme = {
  contentWidth: '1080px',
  innerWidth: '760px',
  contentGap: '40px',
  fontFamily:
    "'Atyp BL Text', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
  fontFamilyTitle:
    "'Atyp BL Display', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
  fontSizeBase: '14px',
  fontSizeMedium: '16px',
  fontSizeLg: '18px',
  headingSize_2: '24px',
  headingSize_3: '20px',
  headingSize_4: '14px',
  lineHeightBase: 1.429,
  primaryColor: '#10006b',
  primaryBgColor: '#f4d70e',
  primaryHoverColor: '#ffffff',
  primaryHoverBgColor: '#10006b',
  primaryDarker: '#11569b',
  textColor: '#333333',
  errorColor: '#ff3840',
  successColor: '#1bb21b',
  warningColor: '#fab559',
  borderColorBase: '#10006b',
  borderColorAdditional: '#e4e3f1',
  tableHeaderBg: '#f2f2fa',
  tableHeaderColor: '#333333',
  tableBorderColor: '#e2e0f0',
  borderRadiusSmall: '3px',
  borderRadiusBase: '0px',
  inputHeightBase: '36px',
  inputPaddingHorizontal: '16px',
  inputPaddingVerticalBase: '0px',
  inputPlaceholderColor: '#9a9a9a',
  inputShadow: 'none',
  disabledColor: '#9a9a9a',
  disabledBg: '#f2f2fa',
  disabledColorBtn: '#dbd9eb',
  infoColor: '#333333',
  infoBgColor: '#fafaff',
  black: '#000000',
  white: '#fff',
  lightGrey: '#f8f8f8',
  bgColor: '#fafaff',
  secondaryColor: '#10006b',
  secondaryBgColor: '#e2e0f0',
  secondaryHoverColor: '#ffffff',
  secondaryHoverBgColor: '#10006b',
  outlineColor: '#05081d',
  outlineBgColor: '#fff',
  outlineBorderColor: '#d5d5d5',
  outlineHoverBgColor: '#f8f8f8',
  outlineDisabledColor: '#737373',
  outlineDisabledBgColor: '#f0f0f0',
  linkColor: '#009efc',
  fieldBottomMargin: '20px',
  navHeight: '36px',
  dashboardGridGap: 20,
  paginationBg: 'transparent',
  paginationBgActive: '#e2e0f0',
  paginationHoverBg: '#f2f2fa',
  labelPadding: '0 0 4px',
  labelLineHeight: 1.53,
  selectHoverBg: '#f2f2fa',
  flagBg: '#399efc',
};

const formItemClass = {
  base: 'item-base',
  small: 'item-sm',
  xsmall: 'item-xsm',
  full: 'item-full',
};
module.exports = { theme, formItemClass };
