import React from 'react';
import { Routes } from './Routes';
import { Stylesheet } from './Stylesheet';
import { BreadcrumbsProvider } from '../common/components/utils/Breadcrumbs/BreadcrumsProvider';

export const App = (): JSX.Element => {
  return (
    <React.Fragment>
      <BreadcrumbsProvider>
        <Routes />
      </BreadcrumbsProvider>

      <Stylesheet />
    </React.Fragment>
  );
};
