import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import 'antd/dist/antd.less';
import { theme } from '../../../theme';
import { SectionNavigation } from '../../components/utils/NavMenu';
import ErrorBoundary from '../../components/utils/ErrorBoundary';

export const BasicLayout: React.FC = ({ children }): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <SectionNavigation />
        <ErrorBoundary>
          <Root className="mainContent">{children}</Root>
        </ErrorBoundary>
      </React.Fragment>
    </ThemeProvider>
  );
};

const Root = styled.div`
  padding-top: ${props => props.theme.navHeight};
  min-height: 100vh;
`;
