import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';

const CustomHistoryContext = React.createContext<History | null>(null);

export const CustomHistoryProvider: React.FC = ({ children }) => {
  const history = useHistory();

  return <CustomHistoryContext.Provider value={history}>{children}</CustomHistoryContext.Provider>;
};

export function useCustomHistory(): History | null {
  return useContext(CustomHistoryContext);
}
